// extracted by mini-css-extract-plugin
export var background = "bio-module--background--a6c6e";
export var container = "bio-module--container--21d08";
export var fadeInVisibility = "bio-module--fadeInVisibility--5b376";
export var fadeOutVisibility = "bio-module--fadeOutVisibility--71926";
export var header = "bio-module--header--636fe";
export var hideNav = "bio-module--hideNav--a312d";
export var intro = "bio-module--intro--6d539";
export var introContainer = "bio-module--introContainer--2af41";
export var invalid = "bio-module--invalid--6a220";
export var paragraph = "bio-module--paragraph--9ad77";
export var paragraph__grow = "bio-module--paragraph__grow--4d972";
export var photoFadeIn = "bio-module--photoFadeIn--fd819";
export var shiftKeyPush = "bio-module--shiftKeyPush--2ad13";