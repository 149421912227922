import React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Bio from "./../components/about/bio/bio";
import FlexCenter from "../components/hoc/flexCenter/flexCenter";

const About = (props) => {
  return (
    <Layout>
      <SEO title="About Sydney SG" />
      <FlexCenter>
        <Bio />
      </FlexCenter>
    </Layout>
  );
};

export default About;
