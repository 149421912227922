import React from "react";
import Portraits from "../portraits/portraits";

import * as bioStyles from "./bio.module.scss";
import { joinClasses } from "../../utils";

const Bio = (props) => {
  return (
    <>
      <div
        className={joinClasses(bioStyles.container, bioStyles.introContainer)}
      >
        <Portraits />
        <div className={bioStyles.intro}>
          <p className={bioStyles.paragraph}>
            Sydney is an art director, photographer and visual designer. Her
            work centers on beauty, fragrance, and accessories, using still life
            and graphic compositions to capture narratives through exploration
            of light, landscape, and object.
          </p>
        </div>
      </div>
    </>
  );
};

export default Bio;
