import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import * as portraitStyles from "./portraits.module.scss";

const Portraits = (props) => {
  //Retrieve portraits in ascending order by name. Webp's with JPEG fallback.
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "portraits" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                height: 1200
                placeholder: BLURRED
                jpgOptions: { quality: 85 }
                webpOptions: { quality: 90 }
              )
            }
            id
          }
        }
      }
    }
  `);

  let images = data.allFile.edges.map((edge) => {
    return (
      <GatsbyImage
        key={edge.node.childImageSharp.id}
        image={edge.node.childImageSharp.gatsbyImageData}
        className={portraitStyles.image}
        placeholderClassName={portraitStyles.placeholder}
        objectFit="contain"
      />
    );
  });

  return (
    <a
      href={"https://www.bentaylorphotos.com"}
      target="_blank"
      rel="noopener"
      className={portraitStyles.container}
    >
      {images}
    </a>
  );
};

export default Portraits;
